//lib
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Font } from "@react-pdf/renderer";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//action

import { getPaymentMethod } from "../../../actions/commonActions";
//import { getOrganisationLogo } from "../../../actions/curUserActions";
import {
  postCreatePresignedUrlOrganisationLogo,
  postCreatePresignedJsonInvoice,
} from "../../../actions/cloudStorageActions";
import {
  putInvoicePrintDate,
  getInvoiceCurrentOrganisation,
  postInvoiceWhatsappAttachmentsWTemplate,
  getInvoiceFileAttachmentNotification,
  getInvoiceTwillioTemplate,
  postInvoiceS3CopyObject,
} from "../../../actions/invoiceActions";
import externetApis from "../../../_apis/externetApis";
//components
import Panel from "../../../components/Panel";
import ConfirmDialog from "../../../components/ConfirmDialog";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import InvoiceDetailsTable from "./components/InvoiceDetailsTable";
import * as generalConstants from "../../../_constants/generalConstants";
import PaymentTable from "../../visit/Consultation/components/editOrder/PaymentTable";
import NotoSansHansRegular from "../../../components/PDF/NotoSansHans-Regular-2.ttf";
import InvoicePDF from "../../../components/PDF/InvoicePDF";
import AmountTotal from "./components/AmountTotal";
import { useStyles } from "../../../components/globalStyles";
import S3OrganisationLogo, {
  setPrintingOrganisationLogo,
} from "../../../components/functions/S3OrganisationLogo";
import EditInvoicePage from "./components/EditInvoicePage";

//register font before calling pdf file
Font.register({
  family: "NotoSansSChinese",
  fonts: [{ src: NotoSansHansRegular }],
});

Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);
//style

const DetailPage = ({
  setOrderProcessView,
  setOrderProcessInfo = () => null,
  orderProcessInfo,
}) => {
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);
  const classes = useStyles();
  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [TCMLogo, setTCMLOGO] = useState(null);
  const common = useSelector((state) => state.common);
  const cloudStorage = useSelector((state) => state.cloudStorage);
  const curUser = useSelector((state) => state.curUser);

  const FILE_UPLOAD_RIGHT = curUser.allRightAccess
    ? curUser.allRightAccess.user_add_permissions.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_INVOICE_RIGHT_TYPE &&
          item.add_permission_type === generalConstants.EDIT_FILE_UPLOAD_TYPE
      )
    : false;

  const [payment, setPayment] = React.useState([]);
  const [paidAmount, setPaidAmount] = React.useState(0);
  const [allowPrint, setAllowPrint] = useState(false);
  const [handleBlob, setHandleBlob] = useState(false);
  const [uploadFileObjects, setUploadFileObjects] = React.useState([]);

  const [msgUploadAlarm, setMsgUploadAlarm] = React.useState(null);
  const [alertMsgUpload, setAlertMsgUpload] = React.useState(false);
  const [alertTypeUpload, setAlertTypeUpload] = React.useState("");
  const [isMsgSending, setIsMsgSending] = React.useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const prevSetPrintDateOnStatus = useRef();
  const prevCreatePresignedJsonStatus = useRef();
  const prevCopyS3ObjectStatus = useRef();
  const prevSendingOutStatus = useRef();
  const price = {
    deliveryFee: orderProcessInfo.store_header.delivery_total_price_for_costing
      ? orderProcessInfo.store_header.delivery_total_price_for_costing
      : 0,
    brewingFee: orderProcessInfo.store_header.brewing_total_price_for_costing
      ? orderProcessInfo.store_header.brewing_total_price_for_costing
      : 0,
    consultationFee: orderProcessInfo.store_header.consultation_total_price
      ? orderProcessInfo.store_header.consultation_total_price
      : 0,
    medicineTab: orderProcessInfo.store_header.medicine_total_price
      ? orderProcessInfo.store_header.medicine_total_price
      : 0,
    medicineFee: orderProcessInfo.store_header.medicine_price_for_costing
      ? orderProcessInfo.store_header.medicine_price_for_costing
      : 0,

    acupuncturePrice: orderProcessInfo.store_header.acupuncture_total_price
      ? orderProcessInfo.store_header.acupuncture_total_price
      : 0,
    treatmentPrice: orderProcessInfo.store_header.treatment_total_price
      ? orderProcessInfo.store_header.treatment_total_price
      : 0,
    miscPrice: orderProcessInfo.store_header.misc_total_price
      ? orderProcessInfo.store_header.misc_total_price
      : 0,
  };

  const discount = {
    deliveryFee: orderProcessInfo.store_header.delivery_discount_amt
      ? orderProcessInfo.store_header.delivery_discount_amt
      : 0,
    brewingFee: orderProcessInfo.store_header.brewing_discount_amt
      ? orderProcessInfo.store_header.brewing_discount_amt
      : 0,
    consultationFee: orderProcessInfo.store_header.consultation_discount_amt
      ? orderProcessInfo.store_header.consultation_discount_amt
      : 0,
    medicineTab: orderProcessInfo.store_header.medicine_discount_amt
      ? orderProcessInfo.store_header.medicine_discount_amt
      : 0,
    acupuncturePrice: orderProcessInfo.store_header.acupuncture_discount_amt
      ? orderProcessInfo.store_header.acupuncture_discount_amt
      : 0,
    treatmentPrice: orderProcessInfo.store_header.treatment_discount_amt
      ? orderProcessInfo.store_header.treatment_discount_amt
      : 0,
    miscPrice: orderProcessInfo.store_header.misc_discount_amt
      ? orderProcessInfo.store_header.misc_discount_amt
      : 0,
  };

  const extraAmt = {
    deliveryFee: orderProcessInfo.store_header.delivery_extra_amt
      ? orderProcessInfo.store_header.delivery_extra_amt
      : 0,
    brewingFee: orderProcessInfo.store_header.brewing_extra_amt
      ? orderProcessInfo.store_header.brewing_extra_amt
      : 0,
    consultationFee: orderProcessInfo.store_header.consultation_extra_amt
      ? orderProcessInfo.store_header.consultation_extra_amt
      : 0,
    medicineTab: orderProcessInfo.store_header.medicine_extra_amt
      ? orderProcessInfo.store_header.medicine_extra_amt
      : 0,
    acupuncturePrice: orderProcessInfo.store_header.acupuncture_extra_amt
      ? orderProcessInfo.store_header.acupuncture_extra_amt
      : 0,
  };

  const [treatmentDetail, setTreatmentDetail] = useState(null);
  const [miscDetail, setMiscDetail] = useState(null);

  // const [logoError, setLogoError] = useState(false);
  // const [logoErrorMsg, setLogoErrorMsg] = useState(null);
  //const curUser = useSelector((state) => state.curUser);
  const invoice = useSelector((state) => state.invoice);

  const [print, setPrint] = React.useState(0);
  const invoiceDetailsWoDeleted = () => {
    return orderProcessInfo.invoice_details.filter(
      (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
    );
  };

  const memoInvoiceDetailsWoDeleted = React.useMemo(invoiceDetailsWoDeleted, [
    orderProcessInfo,
  ]);

  const setPrintingLogo = () => {
    setTCMLOGO(null);
    //const organisationId = orderProcessInfo.organisation.id;

    //dispatch(getOrganisationLogo.pending(organisationId));
    //dispatch(getOrganisation.pending(organisationId));

    setPrint(new Date().valueOf());
    // dispatch(
    //   putInvoicePrintDate.pending({
    //     invoice_header: orderProcessInfo,
    //   })
    // );
    setAllowPrint(false);
    const submitJson = setPrintingOrganisationLogo(
      orderProcessInfo.organisation.codename,
      "organisation",
      orderProcessInfo.organisation.logo
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setPrintingDate = () => {
    dispatch(
      putInvoicePrintDate.pending({
        invoice_header: orderProcessInfo,
      })
    );
  };

  const onHandleBlob = (instance) => {
    if (handleBlob === true) {
      // Convert Blob to File

      const extensionNameIndex = instance.url.lastIndexOf("/");
      const extensionName = instance.url.substring(extensionNameIndex + 1);

      const extensionFormatIndex = instance.blob.type.lastIndexOf("/");
      const extensionFormat = instance.blob.type.substring(
        extensionFormatIndex + 1
      );

      const file = new File(
        [instance.blob],
        `${extensionName}.${extensionFormat}`,
        {
          lastModified: new Date().getTime(),
        }
      );
      setUploadFileObjects(file);

      const yearNumber = dayjs().get("year");
      const monthNumber = dayjs().get("month") + 1; // start 0
      const dateNumber = dayjs().get("date");

      const submitJson = {
        object_name:
          orderProcessInfo.organisation.codename +
          "/" +
          "PUBLIC_ACCESS" +
          "/" +
          yearNumber +
          "/" +
          monthNumber +
          "/" +
          dateNumber +
          "/" +
          extensionName +
          "." +
          extensionFormat,
      };

      dispatch(postCreatePresignedJsonInvoice.pending(submitJson));
      setHandleBlob(false);
    }
  };

  const onConfirmSend = () => {
    setPrintingLogo();
    setHandleBlob(true);
    setIsMsgSending(true);
  };

  //useEffects
  useEffect(() => {
    prevSetPrintDateOnStatus.current = false;
    prevCreatePresignedJsonStatus.current = false;
    prevCopyS3ObjectStatus.current = false;
    prevSendingOutStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getPaymentMethod.pending());
    dispatch(getInvoiceCurrentOrganisation.pending());
    dispatch(
      getInvoiceFileAttachmentNotification.pending({ is_pagination: false })
    );
    dispatch(getInvoiceTwillioTemplate.pending({ is_pagination: false }));
  }, [dispatch]);

  useEffect(() => {
    if (
      invoice.isLoadingPutInvoicePrintDate !== prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current = invoice.isLoadingPutInvoicePrintDate;

      if (
        invoice.putInvoicePrintDateSuccess === true &&
        invoice.isLoadingPutInvoicePrintDate === false
      ) {
        setAllowPrint(true);
      }
    }
  }, [invoice]);

  useEffect(() => {
    if (allowPrint) {
      setOrderProcessInfo(invoice.putInvoicePrintDate.invoice_header);
    }
  }, [invoice.putInvoicePrintDate, allowPrint, setOrderProcessInfo]);

  useEffect(() => {
    if (orderProcessInfo.invoice_details.length > 0) {
      const temAccAmount = orderProcessInfo.invoice_details.reduce(
        (sum, li) => {
          if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
            return parseFloat((sum + li.payment_amount).toFixed(2));
          }
          return parseFloat(sum.toFixed(2));
        },
        0
      );

      setPaidAmount(temAccAmount);
    }
  }, [orderProcessInfo]);

  useEffect(() => {
    var result = [];

    if (orderProcessInfo.store_header.visit_treatment_details.length > 0) {
      orderProcessInfo.store_header.visit_treatment_details.reduce(function (
        res,
        value
      ) {
        if (value.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          if (!res[value.treatment.treatment_type.id]) {
            res[value.treatment.treatment_type.id] = {
              name: value.treatment_type_name,
              amount: 0,
            };
            result.push(res[value.treatment.treatment_type.id]);
          }
          res[value.treatment.treatment_type.id].amount = (
            Number(res[value.treatment.treatment_type.id].amount) +
            Number(value.quantity * value.unit_selling_price)
          ).toFixed(2);
          return res;
        }
        return res;
      },
      {});
    }
    setTreatmentDetail(result);
  }, [orderProcessInfo]);

  useEffect(() => {
    var result = [];

    if (orderProcessInfo.store_header.visit_misc_details.length > 0) {
      const detailsWoDelete =
        orderProcessInfo.store_header.visit_misc_details.filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
      result = detailsWoDelete.map((value) => {
        if (value.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          return {
            ...value,
            amount: Number(value.quantity * value.unit_selling_price).toFixed(
              2
            ),
          };
        }
        return null;
      }, {});
    }

    const resultWoEmpty = result.filter((resultItem) => resultItem !== null);

    setMiscDetail(resultWoEmpty.sort((a, b) => a.row_no - b.row_no));
  }, [orderProcessInfo]);

  useEffect(() => {
    if (
      cloudStorage.isLoadingPostCreatePresignedJsonInvoice !==
      prevCreatePresignedJsonStatus.current
    ) {
      //check the previous ref with current state
      prevCreatePresignedJsonStatus.current =
        cloudStorage.isLoadingPostCreatePresignedJsonInvoice;

      if (
        cloudStorage.postCreatePresignedJsonInvoiceIsSuccess === true &&
        cloudStorage.isLoadingPostCreatePresignedJsonInvoice === false
      ) {
        const submissionJson = {
          postUrl: cloudStorage.postCreatePresignedJsonInvoice.url,
          AWSAccessKeyId:
            cloudStorage.postCreatePresignedJsonInvoice.fields.AWSAccessKeyId,
          key: cloudStorage.postCreatePresignedJsonInvoice.fields.key,
          policy: cloudStorage.postCreatePresignedJsonInvoice.fields.policy,
          signature:
            cloudStorage.postCreatePresignedJsonInvoice.fields.signature,
          file: uploadFileObjects,
        };

        (async () => {
          const response = await externetApis.postS3FileApi(submissionJson);
          if (response.status === 204) {
            const submitJson = {
              "Content-Type": "application/pdf",
              object_name:
                cloudStorage.postCreatePresignedJsonInvoice.fields.key,
            };

            dispatch(postInvoiceS3CopyObject.pending(submitJson));
          } else {
            setMsgUploadAlarm(t("translation:File failed to upload"));
            setAlertMsgUpload(true);
            setAlertTypeUpload("error");
          }
        })();
      }
      if (
        cloudStorage.postCreatePresignedJsonInvoiceIsSuccess === false &&
        cloudStorage.isLoadingPostCreatePresignedJsonInvoice === false
      ) {
        setMsgUploadAlarm(
          cloudStorage.error.postCreatePresignedJsonInvoiceError
        );
        setAlertMsgUpload(true);
        setIsMsgSending(false);
        setAlertTypeUpload("error");
      }
    }
  }, [cloudStorage, dispatch, t]);

  useEffect(() => {
    if (
      invoice.isLoadingPostInvoiceS3CopyObject !==
      prevCopyS3ObjectStatus.current
    ) {
      //check the previous ref with current state
      prevCopyS3ObjectStatus.current = invoice.isLoadingPostInvoiceS3CopyObject;

      if (
        invoice.postInvoiceS3CopyObjectIsSuccess === true &&
        invoice.isLoadingPostInvoiceS3CopyObject === false
      ) {
        if (
          invoice.getInvoiceFileAttachmentNotification
            ?.file_attachment_notifications.length
        ) {
          const submitJson = {
            contact_number: orderProcessInfo.store_header.staff_contact_number,
            country_code: "SG",
            is_remove_media: true,
            file_attachment_notification: {
              id: invoice.getInvoiceFileAttachmentNotification
                ?.file_attachment_notifications[0].id,
            },
            filename: cloudStorage.postCreatePresignedJsonInvoice.fields.key,
            twilio_content_template: {
              id: invoice.getInvoiceTwillioTemplate?.twilio_content_templates?.find(
                (item) =>
                  item.template_purpose ===
                  generalConstants.FILE_ATTACHMENT_TEMPLATE
              ).id,
            },
          };

          dispatch(postInvoiceWhatsappAttachmentsWTemplate.pending(submitJson));
        } else {
          setMsgUploadAlarm(t("translation:File failed to send"));
          setAlertMsgUpload(true);
          setIsMsgSending(false);
          setAlertTypeUpload("error");
        }
      }
      if (
        invoice.postInvoiceS3CopyObjectIsSuccess === false &&
        invoice.isLoadingPostInvoiceS3CopyObject === false
      ) {
        setMsgUploadAlarm(invoice.error.postInvoiceS3CopyObjectError);
        setAlertMsgUpload(true);
        setIsMsgSending(false);
        setAlertTypeUpload("error");
      }
    }
  }, [invoice, cloudStorage, dispatch, t]);

  useEffect(() => {
    if (
      invoice.isLoadingPostInvoiceWhatsappAttachmentsWTemplate !==
      prevSendingOutStatus.current
    ) {
      //check the previous ref with current state
      prevSendingOutStatus.current =
        invoice.isLoadingPostInvoiceWhatsappAttachmentsWTemplate;

      if (
        invoice.postInvoiceWhatsappAttachmentsWTemplateIsSuccess === true &&
        invoice.isLoadingPostInvoiceWhatsappAttachmentsWTemplate === false
      ) {
        setMsgUploadAlarm(t("translation:Invoice has been sent"));
        setAlertMsgUpload(true);
        setIsMsgSending(false);
        setAlertTypeUpload("success");
      }
      if (
        invoice.postInvoiceWhatsappAttachmentsWTemplateIsSuccess === false &&
        invoice.isLoadingPostInvoiceWhatsappAttachmentsWTemplate === false
      ) {
        setMsgUploadAlarm(
          invoice.error.postInvoiceWhatsappAttachmentsWTemplateError
        );
        setAlertMsgUpload(true);
        setIsMsgSending(false);
        setAlertTypeUpload("error");
      }
    }
  }, [invoice, dispatch, t]);
  return (
    <div>
      <S3OrganisationLogo
        organisationCode={orderProcessInfo.organisation.codename}
        folderPath="organisation"
        setPhotoURL={setTCMLOGO}
      />
      <Collapse in={alertMsgUpload}>
        <Alert
          severity={alertTypeUpload}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertMsgUpload(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgUploadAlarm}
        </Alert>
      </Collapse>

      <Typography display="block" variant="h4">
        {orderProcessInfo.order_number}
      </Typography>
      {treatmentDetail && miscDetail && memoInvoiceDetailsWoDeleted && (
        <EditInvoicePage
          orderProcessInfo={orderProcessInfo}
          temPaidAmount={paidAmount}
          temPrice={price}
          temDiscount={discount}
          temExtraAmt={extraAmt}
          temTreatmentDetail={treatmentDetail}
          temMiscDetail={miscDetail}
          temMemoInvoiceDetailsWoDeleted={memoInvoiceDetailsWoDeleted}
        />
      )}
      <Link
        onClick={() => {
          setPrintingLogo();
        }}
        className={classes.boldMaroon075}>
        <Button className={classes.boldMaroon075}>
          {t("translation:PRINT INVOICE")}
        </Button>

        {pdfFontLoaded &&
          TCMLogo &&
          common.paymentMethodList &&
          print !== 0 && (
            <InvoicePDF
              t={t}
              TCMLogo={TCMLogo}
              print={print}
              paidAmount={paidAmount}
              invoiveHeader={orderProcessInfo}
              storeHeader={orderProcessInfo.store_header}
              miscDetail={miscDetail}
              treatmentDetail={treatmentDetail}
              paymentOptions={common.paymentMethodList.payment_methods}
              setPrintingDate={setPrintingDate}
              allowPrint={allowPrint}
              setAllowPrint={setAllowPrint}
            />
          )}
      </Link>

      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSend}
        setOpen={setConfirmSend}
        onConfirm={onConfirmSend}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      {invoice.getInvoiceCurrentOrganisation?.organisation
        .approved_organisation_twilio.length > 0 &&
        invoice.getInvoiceTwillioTemplate !== null &&
        FILE_UPLOAD_RIGHT && (
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              onClick={() => {
                setConfirmSend(true);
              }}
              disabled={isMsgSending}>
              {t("translation:SEND INVOICE")}
            </Button>

            {pdfFontLoaded &&
              TCMLogo &&
              common.paymentMethodList &&
              print !== 0 && (
                <InvoicePDF
                  t={t}
                  TCMLogo={TCMLogo}
                  print={print}
                  paidAmount={paidAmount}
                  invoiveHeader={orderProcessInfo}
                  storeHeader={orderProcessInfo.store_header}
                  miscDetail={miscDetail}
                  treatmentDetail={treatmentDetail}
                  paymentOptions={common.paymentMethodList.payment_methods}
                  setPrintingDate={setPrintingDate}
                  allowPrint={allowPrint}
                  setAllowPrint={setAllowPrint}
                  onHandleBlob={onHandleBlob}
                  handleBlob={handleBlob}
                />
              )}
          </Link>
        )}

      <Panel>
        <PaymentTable
          viewOnly={true}
          price={price}
          discount={discount}
          extraAmt={extraAmt}
          t={t}
          treatmentDetail={treatmentDetail}
          miscDetail={miscDetail}
        />
      </Panel>
      <Panel>
        <AmountTotal
          amountHeader={orderProcessInfo.store_header}
          payment={payment}
          setPayment={setPayment}
          paidAmount={paidAmount}
        />
      </Panel>
      {memoInvoiceDetailsWoDeleted.length > 0 && (
        <Panel>
          <InvoiceDetailsTable
            data={memoInvoiceDetailsWoDeleted}
            t={t}
            viewOnly={true}
          />
        </Panel>
      )}

      <PageOnlyBackAction setView={() => setOrderProcessView(false)} />
    </div>
  );
};

export default DetailPage;

//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";

import moment from "moment";
//components
import * as generalConstants from "../../_constants/generalConstants";
//style

const styles = StyleSheet.create({
  body: {
    maxWidth: "70mm",
    maxHeight: "50mm",
    padding: "3mm",
    paddingTop: "0.5mm",
    paddingBottom: "4mm",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
    width: "70%",
  },
  flexColumn1: { flexDirection: "column", width: "15%" },

  flexColumn2: {
    flexDirection: "column",
    width: "65%",

    left: "3mm",
    bottom: "0.2mm",
    position: "absolute",
    textAlign: "center",
  },
  text: {
    fontSize: "3mm",
    textAlign: "left",
    lineHeight: 1,
    fontFamily: "NotoSansSChinese",
  },
  text1: {
    fontSize: "2.5mm",
    lineHeight: 1.1,
    fontFamily: "NotoSansSChinese",
    textAlign: "center",
  },
  text2: {
    fontSize: "2.66mm",
    lineHeight: 1.05,
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },

  text3: {
    fontSize: "3mm",
    lineHeight: 1.05,
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
});

const ContactHeader = ({ orderDetailInfo }) => {
  if (
    orderDetailInfo.store_header.work_order_type ===
    generalConstants.STORE_WORK_ORDER_TYPE
  ) {
    const sameDefaultBranch =
      orderDetailInfo.store_medicine_header?.organisation_branch.organisation
        .id === orderDetailInfo.store_header.organisation.id
        ? orderDetailInfo.store_medicine_header
        : null;

    if (
      orderDetailInfo.store_header.organisation.is_freelance_organisation ===
      true
    ) {
      return (
        <View style={styles.flexColumn} fixed>
          <Text style={styles.text1} fixed>
            {orderDetailInfo.store_header.staff.name}
          </Text>
          <Text style={styles.text1} fixed>
            Tel No: {orderDetailInfo.store_header.staff.contact_number}
          </Text>
        </View>
      );
    } else {
      if (sameDefaultBranch) {
        return (
          <View style={styles.flexColumn} fixed>
            <Text style={styles.text1} fixed>
              {sameDefaultBranch.branch.desc}
            </Text>
            <Text style={styles.text1} fixed>
              Tel No: {sameDefaultBranch.branch.contact_number}
            </Text>
          </View>
        );
      } else {
        return <View style={styles.flexColumn} fixed></View>;
      }
    }
  }

  if (
    orderDetailInfo.store_header.work_order_type ===
    generalConstants.VISIT_WORK_ORDER_TYPE
  ) {
    if (
      orderDetailInfo.store_header.organisation.is_freelance_organisation ===
      true
    ) {
      return (
        <View style={styles.flexColumn} fixed>
          <Text style={styles.text1} fixed>
            {orderDetailInfo.store_header.physician.name}
          </Text>
          <Text style={styles.text1} fixed>
            Tel No: {orderDetailInfo.store_header.physician.contact_number}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.flexColumn} fixed>
          <Text style={styles.text1} fixed>
            {orderDetailInfo.store_header.visit_branch.desc}
          </Text>
          <Text style={styles.text1} fixed>
            Tel No: {orderDetailInfo.store_header.visit_branch.contact_number}
          </Text>
        </View>
      );
    }
  }
  return <View style={styles.flexColumn} fixed></View>;
};

const PDFPage = ({ orderDetailInfo }) => {
  const NRIC = orderDetailInfo.store_header.staff_identification_number;
  const NRICLength = NRIC ? NRIC.length : 0;
  let NRICLastFourChar = NRIC ? NRIC.substring(NRICLength - 4) : null;

  return (
    <Document>
      <Page orientation="landscape" style={styles.body}>
        <View style={styles.flexRow}>
          <View style={styles.flexColumn1}></View>
          <ContactHeader orderDetailInfo={orderDetailInfo} />
        </View>

        <Text style={styles.text}>
          Date:{" "}
          {moment
            .utc(orderDetailInfo.store_header.created_on)
            .local()
            .format("DD-MMM-YYYY")}
        </Text>

        <Text style={styles.text2}>
          Name: {orderDetailInfo.store_header.staff_name}
          {NRICLastFourChar ? " (" + NRICLastFourChar + ")" : ""}
        </Text>
        <Text style={styles.text2}>
          {orderDetailInfo.store_medicine_header.name}
        </Text>
        <Text style={styles.text}>Prescription Instructions:</Text>
        {orderDetailInfo.store_medicine_header.patient_instructions?.length >
        189 ? (
          <Text style={styles.text2}>
            {orderDetailInfo.store_medicine_header.patient_instructions}
          </Text>
        ) : (
          <Text style={styles.text3}>
            {orderDetailInfo.store_medicine_header.patient_instructions}
          </Text>
        )}

        <View style={styles.flexColumn2}>
          <Text style={styles.text}>
            Expiry Date:{" "}
            {orderDetailInfo.store_medicine_header.proceed_on
              ? moment
                  .utc(orderDetailInfo.store_medicine_header.proceed_on)
                  .local()
                  .add(1, "M")
                  .format("DD-MMM-YYYY")
              : moment
                  .utc(orderDetailInfo.store_header.created_on)
                  .local()
                  .add(1, "M")
                  .format("DD-MMM-YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
let printNum = 0;
const PrescriptionLabelPDF = ({ orderDetailInfo, t, print }) => {
  const [instance, updateInstance] = usePDF({
    document: <PDFPage orderDetailInfo={orderDetailInfo} />,
  });
  useEffect(() => {
    if (!instance.loading && instance.url && print !== printNum) {
      printNum = print;
      window.open(instance.url, "_blank", "noreferrer");
    }
  }, [instance.loading, instance.url, printNum, print]);
  // if (print !== printNum) {
  //   return (
  //     <BlobProvider document={<PDFPage orderDetailInfo={orderDetailInfo} />}>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         var fileURL = [];
  //         if (!loading && blob && print !== printNum) {
  //           printNum = print;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else return <></>;
};

export default PrescriptionLabelPDF;
